var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"viewDashBoard"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"white-row"},[_c('h2',{staticClass:"global-style-heading"},[_vm._v(" Thread Auto Reply Messages "),_c('a',{staticClass:"refreshData",attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.refreshNotificationTable.apply(null, arguments)}}},[_c('i',{staticClass:"fas fa-redo-alt"})])]),(
          typeof _vm.getActiveUserData.user !== 'undefined' &&
            Object.keys(_vm.getActiveUserData.user).length
        )?_c('div',[_c('div',{staticClass:"nofication-body not-len"},[(_vm.getActiveUserData.userRoles == 'admin')?_c('div',[(_vm.auto_reply_messages_loader)?_c('div',{staticClass:"white-row loader-route notification-loader"},[_vm._m(0)]):_vm._e(),(
                typeof _vm.auto_reply_messages.data !== 'undefined' &&
                  Object.keys(_vm.auto_reply_messages.data).length
              )?_c('div',{staticClass:"wrapper-notifications"},[_c('div',{staticClass:"not-groups"},[_c('div',{staticClass:"notifications-listing"},[_c('div',{staticClass:"row"},_vm._l((_vm.auto_reply_messages.data),function(message,message_index){return _c('div',{key:message_index,staticClass:"col-sm-6"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"card-title"},[_vm._v(_vm._s(message.subject))]),_c('p',{staticClass:"card-text",domProps:{"innerHTML":_vm._s('<b>Message :</b> ' + message.body)}}),_c('p',{staticClass:"card-text",domProps:{"innerHTML":_vm._s('<b>From :</b> ' + message.from_user_name + ' ( '+message.from_user_email+' )' )}}),_c('p',{staticClass:"card-text"},[_c('b',[_vm._v("Status")]),_vm._v(" "),_c('span',{class:[
                                  message.status == 'success'
                                  ? 'invoice-status success'
                                  : 'invoice-status danger'
                              ]},[_vm._v(_vm._s(message.status))])]),(message.status != 'success')?_c('a',{staticClass:"btn btn-primary btn-sm",attrs:{"href":"#"},on:{"click":function($event){return _vm.showTermsCondition(message_index)}}},[_vm._v(" View Client Threads ")]):_vm._e()])])])}),0)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('pagination',{staticClass:"pagination justify-content-center",attrs:{"data":_vm.auto_reply_messages,"limit":1},on:{"pagination-change-page":_vm.getThreadAutoReplyMessages}})],1)])])]):_vm._e()]):_c('div',{staticClass:"wrapper-notifications"},[_c('no-record-found')],1)])]):_c('div',{staticClass:"white-row loader-route"},[_vm._m(1)]),_c('modal',{attrs:{"name":"terms-and-condition-modal","height":"auto","width":"60%","scrollable":true}},[_c('div',{staticClass:"ModalLoader"},[_c('sub-children-form',{attrs:{"prop_auto_reply_message_body":_vm.prop_auto_reply_message_body,"prop_auto_reply_message_id":_vm.prop_auto_reply_message_id}})],1)])],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lds-sub-ripple"},[_c('div'),_c('div')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lds-sub-ripple"},[_c('div'),_c('div')])}]

export { render, staticRenderFns }