<template>
  <div class="create-brand" v-cloak>
    <div class="container">
      <transition
        name="fade"
        mode="out-in"
        @beforeLeave="beforeLeave"
        @enter="enter"
        @afterEnter="afterEnter"
      >
        <div key="1" v-if="loader" class="white-row loader-route">
          <div class="lds-sub-ripple">
            <div></div>
            <div></div>
          </div>
        </div>
        <div v-else key="2" class="white-row">
          <div class="convert-leadto-client-form form-area">
            <div class="col-sm-12">
                <div
              class="wrapper-notifications"
                v-if="
                  typeof get_client_thread_listing.data !== 'undefined' &&
                    Object.keys(get_client_thread_listing.data).length
                "
              >
                <div class="not-groups">
                  <div class="notifications-listing"
                  >
                    <h4>User : {{ get_client_thread_listing.data.name }} ( {{ get_client_thread_listing.data.email }} )</h4>
                    <hr>
                    <h5>
                      Message : {{ prop_auto_reply_message_body }}
                    </h5>
                    <form
                      class="post-message-to-thread"
                      v-on:submit.prevent="submit($event)"
                    >
                      <template
                        v-if="
                          typeof get_client_thread_listing.data.messages !== 'undefined' &&
                            Object.keys(get_client_thread_listing.data.messages).length
                        "
                      >
                        <hr>
                        <h5> User Threads : </h5>
                        <div class="form-check"
                          v-for="(user_threads, user_threads_index) in get_client_thread_listing.data.messages"
                          :key="user_threads_index"
                        >
                          <input class="form-check-input" @change="getVal(user_threads_index)"  type="radio" name="selected_thread"  :value="user_threads.id"  :id="'flexRadioDefault1_'+user_threads_index">
                          <label class="form-check-label" :for="'flexRadioDefault1_'+user_threads_index">
                            <p>Project Name : <b>{{ user_threads.project_name }}</b></p>
                            <p>Thread Title : <b>{{ user_threads.porject_thread_subtitle }}</b></p>
                            <p>Last Message : 
                              <b v-html="user_threads.last_reply.message"></b> 
                            </p>
                            <p>
                                <span>Reply by : {{ user_threads.last_reply_user }}</span>
                            </p>

                          </label>
                        </div>

                        <div class="col-sm-12">
                          <div class="row">
                            <div class="form-actions">
                              <button
                                :disabled="disableSubmitBtn"
                                type="submit"
                                id="save-form"
                                class="btn btn-primary default-global-btn"
                              >
                                Post data to thread
                              </button>
                              <div
                                v-if="disableSubmitBtn"
                                class="form_submit_loader"
                              >
                                <div class="lds-ellipsis">
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </template>
                      <template v-else>
                        <hr>
                        <h5> User Threads : </h5>
                        <h6>No item found!</h6>
                      </template>
                    </form>

                  </div>
                </div>
              </div>
              <div v-else class="wrapper-notifications">
                <no-record-found></no-record-found>
              </div>     
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>

import norecordfound from "../../../components/no-record-found";

import { validationMixin } from "vuelidate";
const {
  required,
  minLength,
  email,
  numeric,
  url
} = require("vuelidate/lib/validators");

// import Select2 from "v-select2-component";

export default {
  mixins: [validationMixin],
  name : "auto-repy-get-client-details",
  components: {
    "no-record-found": norecordfound,
  },
  watch: {
    prop_auto_reply_message_id: {
      handler(val){
        if(val) {
          this.loader = false;
          this.getClientDetails();
        }
      },
      deep: true
    },
    prop_auto_reply_message_body: {
      handler(val){
        if(val) {
          this.formData.message_body = val;
        }
      },
      deep: true
    },
  },
  props: [
    'prop_auto_reply_message_id',
    'prop_auto_reply_message_body'
  ],
  data() {
    return {
      disableSubmitBtn : false,
      loader: true,
      data_loader: true,
      formData: {
        thread_id : null,
        message_body : this.prop_auto_reply_message_body,
        user_id : null,
        auto_reply_id : this.prop_auto_reply_message_id,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      },
      get_client_thread_listing: {},
    };
  },
  computed: {
    getActiveUserData() {
      return this.$store.state.auth;
    }
  },
  mounted() {
    this.loader = false;
    this.getClientDetails();
  },
  methods: {
    async submit(event) {
      const $this = this;
      $this.disableSubmitBtn = true;
      // $this.$v.$touch();

      // if ($this.$v.$invalid) {
      //   $this.$store.dispatch("showtostrMessageNotifications", {
      //     message: "Please fill all the fields to proceed form!",
      //     type: "error"
      //   });
      //   $this.disableSubmitBtn = false;
      //   return;
      // }

      const data = $this.$store.dispatch("postHttp", {
        url: "auto-reply-messages/store",
        formDetails: this.formData,
        formEvent: event.target
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          // console.log(response);
          $this.$store.dispatch("displayMessageErrors", {
            response: response,
            formEvent: event.target
          });
        }
      });
      $this.disableSubmitBtn = false;
    },
    getVal(index) {
      const $this = this;
      // console.log($this.get_client_thread_listing.data.messages[index].id);
      let threadid = $this.get_client_thread_listing.data.messages[index].id;
      $this.$set(this.formData, 'thread_id', threadid);
    },
    refreshBrandTable() {
      this.getClientDetails();
    },

    async getClientDetails() {
      const $this = this;
      $this.data_loader = true;
      const data = $this.$store.dispatch("getHttp", {
        url:
          "auto-reply-messages/get-user/" +
          this.prop_auto_reply_message_id + 
          "?timezone=" +
          Intl.DateTimeFormat().resolvedOptions().timeZone
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          // console.log(response.data);
          $this.get_client_thread_listing = response.data;
          $this.formData.user_id = response.data.data.id;
          $this.data_loader = false;
        }
      });
    },
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },
    enter(element) {
      const { height } = getComputedStyle(element);

      element.style.height = this.prevHeight;

      setTimeout(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = "auto";
    },
    async onSubmit(event,lead_index) {
      
    //   const $this = this;
    //   $this.disableSubmitBtn = true;
    //   $this.$v.$touch();

    //   if ($this.$v.$invalid) {
    //     $this.$store.dispatch("showtostrMessageNotifications", {
    //       message: "Please fill all the fields to proceed form!",
    //       type: "error"
    //     });
    //     $this.disableSubmitBtn = false;
    //     return;
    //   }

    //   const data = $this.$store.dispatch("postHttp", {
    //     url: "leads/convert-lead-to-client",
    //     formDetails: this.formData,
    //     formEvent: event.target
    //   });
    //   await data.then( (response) => {
    //     if (typeof response !== "undefined") {
    //       // console.log(response.data[0]);
    //       $this.$store.dispatch("showtostrMessageNotifications", {
    //         message: response.data.message,
    //         type: 'success'
    //       });
    //       this.$emit('updateLeadData', response.data[0]);
    //     }
    //   });
    //   $this.disableSubmitBtn = false;
    },
  }
};
</script>
<style lang="scss" scoped>

.form-check {
    clear: both;
    margin-bottom: 20px;
}

</style>