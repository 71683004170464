<template>
  <div class="viewDashBoard" v-cloak>
    <div class="container">
      <div class="white-row">
        <h2 class="global-style-heading">
          Thread Auto Reply Messages
          <a
            @click.prevent="refreshNotificationTable"
            href=""
            class="refreshData"
            ><i class="fas fa-redo-alt"></i
          ></a>
        </h2>
        <div
          v-if="
            typeof getActiveUserData.user !== 'undefined' &&
              Object.keys(getActiveUserData.user).length
          "
        >
          <div class="nofication-body not-len">
            <div v-if="getActiveUserData.userRoles == 'admin'">
              <div
                v-if="auto_reply_messages_loader"
                class="white-row loader-route notification-loader"
              >
                <div class="lds-sub-ripple">
                  <div></div>
                  <div></div>
                </div>
              </div>
              <div
                class="wrapper-notifications"
                v-if="
                  typeof auto_reply_messages.data !== 'undefined' &&
                    Object.keys(auto_reply_messages.data).length
                "
              >
                <div class="not-groups">
                  <div class="notifications-listing"
                  >


                    <div class="row">
                      <div class="col-sm-6"
                        v-for="(message, message_index) in auto_reply_messages.data"
                        :key="message_index"
                      >
                        <div class="card">
                          <div class="card-body">
                            <h5 class="card-title">{{ message.subject }}</h5>
                            <p class="card-text" v-html="'<b>Message :</b> ' + message.body"></p>
                            <p class="card-text" v-html="'<b>From :</b> ' + message.from_user_name + ' ( '+message.from_user_email+' )' "></p>
                            <p
                              class="card-text"
                            >
                              <b>Status</b> <span
                                :class="[
                                    message.status == 'success'
                                    ? 'invoice-status success'
                                    : 'invoice-status danger'
                                ]"
                              >{{ message.status }}</span>
                            </p>
                            <a href="#" class="btn btn-primary btn-sm"
                              v-if="message.status != 'success'"
                              @click="showTermsCondition(message_index)"
                            >
                              View Client Threads
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>


                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <pagination
                        class="pagination justify-content-center"
                        :data="auto_reply_messages"
                        :limit="1"
                        @pagination-change-page="getThreadAutoReplyMessages"
                      ></pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-else class="wrapper-notifications">
              <no-record-found></no-record-found>
            </div>
          </div>
        </div>
        <div v-else class="white-row loader-route">
          <div class="lds-sub-ripple">
            <div></div>
            <div></div>
          </div>
        </div>

        <modal
            name="terms-and-condition-modal"
            height="auto"
            width="60%"
            :scrollable="true"
          >
            <div class="ModalLoader">
              <sub-children-form :prop_auto_reply_message_body="prop_auto_reply_message_body" :prop_auto_reply_message_id="prop_auto_reply_message_id"></sub-children-form>
              <!-- <show-lead-details :prop_lead_id="prop_lead_id" @updateLeadStatus="updateLeadStatus($event)" @updateLeadIcon="updateLeadIcon($event)"></show-lead-details> -->
              <!-- <show-lead-details></show-lead-details> -->
            </div>
        </modal>


      </div>
    </div>
  </div>
</template>

<script>
import norecordfound from "../../components/no-record-found";
import sub_children_form from "./children/sub-index";
// import Avatar from "vue-avatar";
export default {
  components: {
    // Avatar,
    "no-record-found": norecordfound,
    "sub-children-form": sub_children_form,
  },
  computed: {
    getActiveUserData() {
      return this.$store.state.auth;
    }
  },
  data() {
    return {
      auto_reply_messages_loader: true,
      total_count: 0,
      auto_reply_messages: {},
      prop_auto_reply_message_index : null,
      prop_auto_reply_message_id : null,

      prop_auto_reply_message_body : null,
    };
  },
  mounted() {
    document.title = this.$router.app._route.meta.pageTitle;
    this.getThreadAutoReplyMessages();
  },
  methods: {
    showTermsCondition(message_index) {
      // alert(message_index);
      this.prop_auto_reply_message_index = message_index;
      this.prop_auto_reply_message_id = this.auto_reply_messages.data[this.prop_auto_reply_message_index].id;
      this.prop_auto_reply_message_body = this.auto_reply_messages.data[this.prop_auto_reply_message_index].body;
      this.$modal.show("terms-and-condition-modal");
    },
    hideTermsCondition() {
      this.$modal.hide("terms-and-condition-modal");
    },
    refreshNotificationTable() {
      this.getThreadAutoReplyMessages();
    },
    async getThreadAutoReplyMessages(page = 1, action = null) {
      const $this = this;
      $this.auto_reply_messages_loader = true;
      const data = $this.$store.dispatch("getHttp", {
        url:
          "auto-reply-messages?timezone=" +
          Intl.DateTimeFormat().resolvedOptions().timeZone +
          "&page=" +
          page
      });
      await data.then(function(response) {
        if (typeof response != "undefined") {
          // console.log(response);
          $this.auto_reply_messages = response.data;
          $this.auto_reply_messages_loader = false;
        }
      });
    },
    async markAsRead(id, redirect, read_at) {
      const $this = this;
      $this.auto_reply_messages_loader = true;
      if (read_at === null) {
        const data = $this.$store.dispatch("postHttp", {
          url: "notifications/mark-as-read/" + id,
          formDetails: {
            id: id
          },
          formEvent: null
        });
        await data.then(function(response) {
          if (typeof response !== "undefined") {
            $this.$store.dispatch("getingTotalCountOfNotification");
            $this.getThreadAutoReplyMessages();
            if (redirect.popup) {
              // console.log("hey");
            } else {
              if ($this.$route.fullPath == redirect.url) {
                $this.$router.push(redirect.url + "?reload=1");
              } else {
                $this.$router.push(redirect.url);
              }
              $this.auto_reply_messages_loader = true;
            }
          }
        });
      } else {
        $this.$store.dispatch("getingTotalCountOfNotification");
        $this.getThreadAutoReplyMessages();
        if (redirect.popup) {
          // console.log("hey");
        } else {
          if ($this.$route.fullPath == redirect.url) {
            $this.$router.push(redirect.url + "?reload=1");
          } else {
            $this.$router.push(redirect.url);
          }
        }
        $this.auto_reply_messages_loader = true;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
body .white-row {
    position: relative;
}
body .white-row.loader-route {
    height: 150px;
    position: absolute;
    top: unset;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: none;
    width: 100%;
    left: 0;
    height: 92%;
    bottom: 0;
    z-index: 9;
}
.card {
    margin-bottom: 10px;
}
.danger {
    background: red;
    color: #fff !important;
    border-radius: 12px;
    padding: 0 10px 3px;
    font-size: 12px;
}
.success {
    background: rgba(0, 128, 0, 0.72);
    color: #fff !important;
    border-radius: 12px;
    padding: 0 10px 3px;
    font-size: 12px;
}
h2.global-style-heading {
  padding-bottom: 20px;
}
</style>
